// extracted by mini-css-extract-plugin
export var closeBtn = "gallery-module--close-btn--XslmY";
export var enter = "gallery-module--enter--DXKjV";
export var enterActive = "gallery-module--enter-active--jo59O";
export var exit = "gallery-module--exit--LJc1T";
export var exitActive = "gallery-module--exit-active--42WeU";
export var gallery = "gallery-module--gallery--gb0c0";
export var image = "gallery-module--image--vgxCT";
export var navigation = "gallery-module--navigation--u8KQo";
export var nextBtn = "gallery-module--next-btn--0aCIL";
export var pagination = "gallery-module--pagination--sfgCY";
export var prevBtn = "gallery-module--prev-btn--u8gx5";
export var slide = "gallery-module--slide--I4coL";
export var slider = "gallery-module--slider--OQUB7";
export var topBar = "gallery-module--top-bar--ov3DL";