import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useForceRender } from '../../hooks/force-render';
import { CSSTransition } from 'react-transition-group';

import * as styles from './gallery.module.scss';

const Gallery = ({ state: requestedState, images }) => {
	const forceRender = useForceRender();

	const [opened, setOpened] = useState(false);
	const paginationRef = useRef(null);
	const prevBtnRef = useRef(null);
	const nextBtnRef = useRef(null);

	useEffect(() => {
		setOpened(requestedState?.opened);
	}, [requestedState]);

	return (
		<CSSTransition
			in={opened}
			timeout={310}
			mountOnEnter={true}
			unmountOnExit={true}
			classNames={{ ...styles }}
			onEnter={() => {
				document?.body?.classList?.add('no-scroll');
			}}
			onEntered={() => {
				forceRender();
			}}
			onExited={() => {
				document?.body?.classList?.remove('no-scroll');
			}}
		>
			<div className={styles.gallery}>
				<div className={styles.topBar}>
					<div ref={paginationRef} className={styles.pagination} />

					<button
						className={styles.closeBtn}
						onClick={() => setOpened(false)}
						aria-label="Zavřít galerii"
					>
						<i className="fal fa-times" />
					</button>
				</div>

				<Swiper
					slidesPerView={1}
					spaceBetween={50}
					navigation={{
						prevEl: prevBtnRef.current,
						nextEl: nextBtnRef.current,
					}}
					pagination={{
						el: paginationRef.current,
						type: 'fraction',
					}}
					initialSlide={requestedState?.slide ?? 0}
					keyboard={true}
					className={styles.slider}
				>
					{images?.map((image, i) => (
						<SwiperSlide key={`projectImage${i}`} className={styles.slide}>
							<figure>
								<GatsbyImage
									title={image?.meta?.title}
									alt={image?.meta?.alt ?? ''}
									image={getImage(image?.file)}
									objectFit="contain"
									objectPosition="center"
									className={styles.image}
								/>

								{image?.meta?.description?.length > 0 && (
									<figcaption>{image?.meta?.description}</figcaption>
								)}
							</figure>
						</SwiperSlide>
					))}
				</Swiper>

				<div className={styles.navigation}>
					<button
						ref={prevBtnRef}
						className={styles.prevBtn}
						aria-label="Přejít na předchozí snímek"
					>
						<i className="fal fa-chevron-left" />
					</button>
					<button ref={nextBtnRef} className={styles.nextBtn} aria-label="Přejít na další snímek">
						<i className="fal fa-chevron-right" />
					</button>
				</div>
			</div>
		</CSSTransition>
	);
};

Gallery.defaultProps = {
	state: {
		opened: false,
		slide: 0,
	},
	images: [],
};

Gallery.propTypes = {
	state: PropTypes.shape({
		opened: PropTypes.bool.isRequired,
		slide: PropTypes.number,
	}),
	images: PropTypes.arrayOf(
		PropTypes.shape({
			meta: PropTypes.object,
			file: PropTypes.object,
		})
	),
};

export default Gallery;
